import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout";
import SEO from "../components/seo";

import "./styles/index.css";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  const headlinePost = posts[0];
  const headlineTitle = headlinePost.node.frontmatter.title || headlinePost.node.fields.slug;
  const headlineImgFluid = headlinePost.node.frontmatter.featuredImage.childImageSharp.fluid;

  return (
    <Layout location={location} title={siteTitle}>
    <div
      style={{
        backgroundColor: 'var(--bg-alt)',
        display: `block`
      }}>
      <SEO title="Home" />

          <div
            style={{
              backgroundColor: 'var(--bg-reading)',
              margin: `0 auto`,
              maxWidth: `var(--maxWidth-4xl)`
            }}
            >
            <section
              style={{
                padding: `var(--spacing-12) var(--spacing-5)`,
              }}
                >
              <Link
                style={{
                  color: `inherit`,
                  display: 'flex',
                  boxShadow: 'none',
                }}
                className='headline-container'
                key={headlinePost.node.fields.slug}
                to={headlinePost.node.fields.slug}
                >
                  <div
                    className='headline-text-container'
                    >
                    <h3
                      className="headline-text"
                      >{headlineTitle}</h3>
                    <small>{headlinePost.node.frontmatter.date}</small>
                  </div>
                  <div
                    className='headline-image-container'
                    >
                  <Image
                    fluid={headlineImgFluid}
                    alt={headlinePost.node.frontmatter.featuredImageAlt}
                    style={{
                      margin: `0`,
                      width: `100%`
                    }}
                    className='headline-image'
                  />
                  </div>
              </Link>
            </section>
            <section
              style={{
                margin: `0 auto`,
                maxWidth: `var(--maxWidth-3xl)`,
                padding: `var(--spacing-12) var(--spacing-5)`,
              }}>

        {posts.map(({ node }, i) => {
          const title = node.frontmatter.title || node.fields.slug;
          const featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid;
          if (i < 1) {
            return (null)
          }
          return (
             <Link
                to={node.fields.slug}
                className="post-container"
                 key={node.fields.slug}
                 style={{
                   color: `inherit`,
                   display: 'flex',
                   boxShadow: 'none'
                 }}
                 >
               <div
                 style={{
                   'flexBasis': `75%`
                 }}
                 className="post-text-container"
                 >
                 <h3
                   className="headline-text"
                   style={{
                     'marginBottom': `var(--spacing-1)`
                   }}
                 >
                  {title}
                 </h3>
                 <small>{node.frontmatter.date}</small>
               </div>
                <Image
                  fluid={featuredImgFluid}
                  alt={node.frontmatter.featuredImageAlt}
                  style={{
                    width: `50%`
                  }}
                />

           </Link>
          )
        })}
      </section>
      </div>

    </div>
    </Layout>

  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark( filter: {frontmatter: {templateKey: {eq: "post"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            templateKey
            date(formatString: "MMMM D, YYYY")
            title
            description
            featuredImageAlt
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
